@charset 'UTF-8';

// Copyright
// ---------
.copyright-wrapper {
  background: $background-copyright;
  border-top: 1px solid $gray-200;
  flex: none;
  padding: .8em map-unit( $copyright-container-padding, sm );
  position: relative;
  width: 100%;
  z-index: map-unit( $z-index, copyright );
  @include media(md) {
    padding: .8em map-unit( $copyright-container-padding, md );
  }
  @include media(lg) {
    padding: .8em map-unit( $copyright-container-padding, lg );
  }
  @include widthWrapper( $copyright-container-width, $copyright-container-padding );
}

.copyright-container {
  margin: 0 auto;
  @include widthContainer( $copyright-container-width );
}

.copyright {
  color: $body_text_color;
  font-size: 80%;
  text-align: center;
}

