@charset 'UTF-8';

// Common Layout
//
.main_contents_wrapper {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}

.main_contents_column {
  order: 0;
  width: 100%;
  @include media(md){
    flex: 1 0 1%;
  }
  @include media(lg){
    order: 1;
  }

  section + section {
    margin-top: map-get( $section-gutter, sm ) * 2;
    @include media(md){
      margin-top: map-get( $section-gutter, md ) * 2;
    }
    @include media(lg){
      margin-top: map-get( $section-gutter, lg ) * 2;
    }
  }
}

.side_contents_column {
  margin-top: 2em;
  order: 1;
    width: 100%;
    @include media(lg) {
      order: 0;
      margin-top: 0;
      margin-right: 36px;
      width: 242px;
    }
}



// TOP
// ----------------------------

.visual-wrapper{
  background: #f2cbc6;
  margin-bottom: 4em;
  .visual-container{
    padding: 0 map-unit( $container-padding, sm );
    @include media(md) {
      padding: 0 map-unit( $container-padding, md );
    }
    @include media(lg) {
      padding: 0 map-unit( $container-padding, lg );
    }
    .visual-inner{
      position: relative;
      margin: 0 auto;
      height: 450px;
      @include widthContainer( $container-width );
      @include media(md) {
      }
      .visual-text{
        position: absolute;
        left: 0;
        top: 2em;
        font-weight: 400;
        line-height: 1.7;
        //color:  #e25f6b;
        font-size: 1.125em;
        z-index: 1;
        color: #595959;
        box-shadow: 0px 0px 50px rgba(255, 255, 255, 1);
        background: #F8E1DF;
        border-radius: 50%;
        padding: 2em 1em;
        text-align: center;
        white-space: nowrap;
        @include media(md) {
          font-size: 1.5em;
          top: 50%;
          transform: translateY(-50%);
        }
        @include media(lg) {
          left: 8rem;
        }
      }
      .visual-img{
        position: absolute;
        right: 0;
        bottom: 0;
        width: 220px;
        max-width: 50%;
        @include media(md) {
          width: 280px;
        }
        @include media(lg) {
          width: 280px;
          right: 8rem;
        }
      }
    }
  }
}

// Topics Element
.topics_element {
  background: $white-color;
  height: 100%;
  position: relative;
  > a {
    border-top: 4px solid $base-color;
    // border-left: solid 1px #ccc;
    // border-right: solid 1px #ccc;
    // border-bottom: solid 1px #ccc;
    color: $body-text-color;
    display: block;
    height: 100%;
    padding-top: 1em;
    transition: $transition;
    box-shadow: 0px 0px 5px 0px rgba(204,204,204,1);
    &:before {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
    }
    &:hover {
      background: $second-color;
      //border-top: 4px solid lighten( $base-color, 20% );
      //color: #fff;
      //box-shadow: none;
    }
  }

  &_image {
    border-radius: 50%;
    overflow: hidden;
    position: relative;
    margin: 0 auto;
    width: 140px;
    height: 140px;
    img {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate( -50%, -50% );
    }
    @include media(md) {
      width: 180px;
      height: 180px;
    }
  }

  &_body {
    font-size: .875em;
    line-height: 1.5;
    padding: 1em;
    .title {
      font-size: 1.25em;
      font-weight: bold;
      line-height: 1.3;
      margin-bottom: .4em;
      text-align: center;
      color: $first-color;
    }
  }
}



// News Element
.news_element {
  background: $white-color;
  height: 100%;
  position: relative;

  > a {
    color: $gray-700;
    display: block;
    height: 100%;
    transition: background .25s;
    &:before {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
    }
    &:hover {
      background: #edf9f9;
    }
  }

  &_tag {
    background: $base-color;
    color: $white-color;
    display: table;
    font-size: 87.5%;
    float: right;
    padding: .2em .6em;
    position: absolute;
    top: -5%;
    right: 0.5em;
    z-index: 1;
  }

  &_body {
    line-height: 1.4;
    padding: 1em;
    &:after {
      content: '\f138';
      color: $gray-300;
      font-family: $icon-font-family;
      font-size: 1.6em;
      font-weight: 900;
      display: block;
    }
    .date {
      color: $first-color;
      font-size: 87.5%;
      font-weight: bold;
      line-height: 1.3;
      margin-bottom: .4em;
      & span {
        font-size: 140%;
      }
    }
  }
}



// Side Column
//

// Menu List
.side_column_list_menu {
  background: $white-color;
  width: 100%;
  li {
    position: relative;
    width: 90%;
    margin: 0 auto;
    &:before {
      content: '';
      background: $base-color;
      display: inline-block;
      width: 3px;
      height: 50%;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }
    &:not(:last-child) {
      border-bottom: 1px solid $gray-300;
    }
    a {
      color: $gray-800;
      display: block;
      padding: 1em;
      transition: background .2s;
      &:hover {
        background: #F9F9FA;
        text-decoration: none;
      }
    }
  }
}

// Banner
.side_column_banner {
  width: 100%;
  margin: 2em auto;
  @include media(md) {
    width: 100%;
  }
  @include media(lg) {
    width: 200px;
  }
}



// Sub Pages
//

// hero
.hero {
  color: inherit;
  position: relative;
  width: 100%;
  &_title {
    font-size: 1.5em;
    line-height: 1.2;
    position: absolute;
    top: 50%;
    left: .5em;
    transform: translateY(-50%);
    text-shadow: 1px 1px 2px #fff, 0 0 1em #fff, 0 0 0.2em #fff;
    @include media(md) {
      font-size: $h1-font-size;
    }
    @include media(lg) {
      font-size: $h1-font-size;
    }
  }
}

.map-wrapper{
  height: 50vh;
  // height: 48rem;
  // @include media(md) {
  //   height: 64rem;
  // }
}

.contact-wrapper{
  //border: solid 1px $base-color;
  padding: 1.5em;
  text-align: center;
  background: $second-color;
  .contact-title{
    font-size: 1.25em;
    font-weight: bold;
    color: $first-color;
    margin-bottom: 0.5em;
  }
  .contact-phone{
    margin-bottom: 1em;
    display: block;
    color: $body_text_color;
    span{
      font-size: 1.25em;
      //font-weight: bold;
    }
    &::before{
      content: '\f3cd';
      font-family: $icon-font-family;
      font-weight: 900;
      font-size: 1.25em;
      margin-right: 0.5em;
    }
  }
}

.prefecture{
  margin-top: 1.25em;
  margin-bottom: 0.5em;
  padding-bottom: 0.25em;
  color: $first-color;
  border-bottom: dotted 1px $body_text_color;
}

.kanren-link-facility{
  background: $second-color;
  padding: 0.5em;
  font-size: 1.125em;
  text-align: center;
  margin-bottom: 1em;
  //color: #fff;
}

.kanren-link{
  li{
    & + li{
      margin-top: 0.5em;
    }
  }
  a{
    color: $body_text_color;
    position: relative;
    padding-left: 1em;
    transition: 0.25s;
    &:hover{
      color: $first-color;
    }
    &::before{
      content: '\f054';
      font-family: $icon-font-family;
      font-weight: 900;
      color: $first-color;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      line-height: 1;
      font-size: 0.75em;
    }
  }
}

.service-wrapper{
  background: $second-color;
  padding: 1.4rem 1.4rem 0.7rem 1.4rem;
  margin-top: 1.5em;
  @include media(md) {
    padding: 1.6rem 1.6rem 0.6rem 1.6rem;
  }
  @include media(lg) {
    padding: 2rem 2rem 0.8rem 2rem;
  }
}

.logo-list{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  @include media(md) {
    justify-content: flex-start;
  }
  .logo-items{
    padding: 0.5em 1em;
  }
  .logo-img{
    height: 4.8rem;
    @include media(md) {
      height: 6rem;
    }
  }
}

figcaption{
  font-size: 0.875em;
  margin-top: 1em;
  line-height: 1.4;
  color: #595959;
}