@charset 'UTF-8';

// typography
// ----------

h1,
h2,
h3,
h4,
h5,
h6 {
  font-style: normal;
  text-rendering: optimizeLegibility;
  margin-top: 0;
  margin-bottom: .4em;
  line-height: 1.4;
}

h1 {
  font-size: $h1-font-size;
  &.first {
    border-left: 1px solid $base-color;
    color: $base-color;
    padding: 0 0 .1em .4em;
  }
}

h2 {
  font-size: $h2-font-size;
  &.first {
    position: relative;
    padding: .75em 1em .75em 1.5em;
    border: 1px solid #ccc;
    margin-bottom: 1em;
    &:after {
      position: absolute;
      top: .5em;
      left: .5em;
      content: '';
      width: 3px;
      height: calc( 100% - 1em );
      background: $first-color;
      //border-radius: 4px;
    }
  }
}

h3 {
  font-size: $h3-font-size;
  &.first {
    color: $body_text_color;
    position: relative;
    margin-top: 1.25em;
    margin-bottom: 1em;
    // &::before {
    //   content: '';
    //   position: absolute;
    //   bottom: -1rem;
    //   width: 10rem;
    //   border-bottom: 2px solid $base-color;
    // }
    span{
      position: relative;
      &::after {
        content: '';
        position: absolute;
        bottom: -1rem;
        left: 0;
        width: 100%;
        border-bottom: 1px solid $base-color;
      }
    }
  }
}

h4 {
  font-size: $h4-font-size;
  &.first {
    border-bottom: 1px dotted $base-color;
    color: $first-color;
    margin-bottom: .4em;
    padding-bottom: .4em;
    margin-top: 2em;
  }
}

h5 {
  font-size: $h5-font-size;
}

h6 {
  font-size: $h6-font-size;
}


p {
  font-size: inherit;
  letter-spacing: map-unit( $paragraph-letter-spacing, sm );
  line-height: map-unit( $paragraph-line-height, sm );
  text-rendering: optimizeLegibility;
  @include media(md) {
    letter-spacing: map-unit( $paragraph-letter-spacing, md );
    line-height: map-unit( $paragraph-line-height, md );
  }
  @include media(lg) {
    letter-spacing: map-unit( $paragraph-letter-spacing, lg );
    line-height: map-unit( $paragraph-line-height, lg );
  }
}

// 主に paragraph を段落として使用しない場合に margin-bottom を打ち消す
.phrase {
  margin-bottom: 0 !important;
}

// イタリック
// -------
i {
  font-style: italic;
  line-height: inherit;
}

// ボールド
// ------
strong,
em,
b {
  font-style: normal;
  font-weight: bold;
  line-height: inherit;
}

// code
// ----
pre {
  background: #f2f2f2;
  margin: 1em 0;
  padding: 1em;
  white-space: pre-wrap;
  word-wrap: break-word;
}

code {
  background: transparent;
  color: #545454;
  font-family: 'Source Code Pro', monospace;
  font-size: .9375em;
  font-weight: bold;
  vertical-align: initial;
}
