@charset 'UTF-8';

// burger
// ------

// border color
$burger-border-color: $gray-800;

// bar の太さ
$burger-border-thickness: 2px;

// bar の長さ
$burger-border-width: (
  'sm': 24px,
  'md': 24px,
  'lg': 24px
);

// bar 同士の間隔
$burger-border-margin: (
  'sm': 6px,
  'md': 6px,
  'lg': 4px
);

// burger open 時に付与するクラス名
$burger-open-class: '.open-menu';
