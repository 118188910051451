@charset 'UTF-8';

// wrapper
.burger_wrapper {
  cursor: pointer;
  display: flex;
  align-items: center;
  line-height: 1;
  position: relative;
  @include media(lg){
    display: none;
  }
}

.burger_text {
  display: block;
  margin-right: 6px;
}



// Burger
// ------
.burger_icon {
  width: map-get( $burger-border-width, 'sm' );
  span {
    background: $burger-border-color;
    display: block;
    width: 100%;
    height: $burger-border-thickness; // 太さ
    transition: transform $transition, opacity $transition;
    &:nth-of-type(2) {
      margin: map-get( $burger-border-margin, 'sm' ) 0; // ボーダー同士の間隔
    }
  }
  @include media(md) {
    width: map-get( $burger-border-width, 'md' );
    span:nth-of-type(2) {
      margin: map-get( $burger-border-margin, 'md' ) 0; // ボーダー同士の間隔
    }
  }
  @include media(lg) {
    width: map-get( $burger-border-width, 'lg' );
    span:nth-of-type(2) {
      margin: map-get( $burger-border-margin, 'lg' ) 0; // ボーダー同士の間隔
    }
  }
  // OPEN
  #{$burger-open-class} & {
    background: transparent;
    span {
      &:nth-of-type(1) {
        transform: translateY(8px) rotate(-45deg);
      }
      &:nth-of-type(2) {
        opacity: 0;
      }
      &:nth-of-type(3) {
        transform: translateY(-8px) rotate(45deg);
      }
    }
    @include media(md) {
      span {
        &:nth-of-type(1) {
          transform: translateY(8px) rotate(-45deg);
        }
        &:nth-of-type(2) {
          opacity: 0;
        }
        &:nth-of-type(3) {
          transform: translateY(-8px) rotate(45deg);
        }
      }
    }
    @include media(lg) {
      span {
        &:nth-of-type(1) {
          transform: translateY(6px) rotate(-45deg);
        }
        &:nth-of-type(2) {
          opacity: 0;
        }
        &:nth-of-type(3) {
          transform: translateY(-6px) rotate(45deg);
        }
      }
    }
  }
}



