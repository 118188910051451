@charset 'UTF-8';

// Mixin
// ========== ========== ========== ========== ========== ==========



// メディアクエリの書き出し
//
@mixin media( $data )
{
  $key: map-get( $breakpoints, $data );
  @media screen and ( min-width: convertEm($key) ) {
    @content;
  }
}



// grid
//
@mixin make-grid( $width )
{
  flex: 0 0 $width;
  width: $width;
  max-width: $width;
}



// icon
//
@mixin icon-default
{
  font-family: $icon-font-family;
  content: map-get( $icon-type, 'arrows' );
  color: inherit;
  display: inline-block;
  font-weight: 900;
  width: 1.28571429em;
  text-align: center;
}



// fluid width
//
@mixin widthWrapper( $width, $padding )
{
  @if ( $fluid-sm == false ) {
    min-width: map-unit( $width, sm ) + map-unit( $padding, sm ) * 2;
  }
  @if ( $fluid-md == false ) {
    @include media(md) {
      min-width: map-unit( $width, md ) + map-unit( $padding, md ) * 2;
    }
  }
  @if ( $fluid-lg == false ) {
    @include media(lg) {
      min-width: map-unit( $width, lg ) + map-unit( $padding, lg ) * 2;
    }
  }
}

@mixin widthContainer( $width )
{
  @if ( $fluid-sm == true ) {
    width: 100%;
    max-width: map-unit( $width, sm );
  } @else {
    width: map-unit( $width, sm );
  }
  @if ( $fluid-md == true ) {
    @include media(md) {
      max-width: map-unit( $width, md );
    }
  } @else {
    @include media(md) {
      width: map-unit( $width, md );
    }
  }
  @if ( $fluid-lg == true ) {
    @include media(lg) {
      max-width: map-unit( $width, lg );
    }
  } @else {
    @include media(lg) {
      width: map-unit( $width, lg );
    }
  }
}
