@charset 'UTF-8';

// footer
// ------

footer {
  background: transparent;
  flex: none;
  padding-top: map-unit( $footer-padding-top, sm );
  width: 100%;
  z-index: map-unit( $z-index, footer );
  @include media(md) {
    padding-top: map-unit( $footer-padding-top, md );
  }
  @include media(lg) {
    padding-top: map-unit( $footer-padding-top, lg );
  }
}

.footer-wrap {
  background: $background-footer;
  border-top: 1px solid $gray-200;
  position: relative;
  padding: 1em map-unit( $footer-container-padding, sm ) 0;
  @include media(md) {
    padding: 1em map-unit( $footer-container-padding, md ) 0;
  }
  @include media(lg) {
    padding: 1em map-unit( $footer-container-padding, lg ) 0;
  }
  @include widthWrapper( $footer-container-width, $footer-container-padding );
}

.footer-container {
  margin: 0 auto;
  @include widthContainer( $footer-container-width );
}

.footer_inner_upper {
  display: block;
  @include media(lg){
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.footer_inner_upper_left {
  // width: 100%;
  // @include media(lg){
  //   width: 60%;
  // }
  .footer_company_list {
    line-height: 1.6;
    text-align: center;
    @include media(lg){
      text-align: left;
    }
    li {
      &.name {
        font-size: 1.125em;
        //margin-bottom: 0.5em;
        font-weight: 500;
      }
      &.address{
        font-size: 0.875em;
        @include media(lg){
          white-space: nowrap;
        }
        .access{
          display: block;
          width: 10em;
          background: $first-color;
          padding: 0.25em 0.5em;
          color: #fff;
          border-radius: 0.25em;
          margin: 0.5em auto;
          transition: 0.25s;
          box-shadow: 0px 1px 1px 0px rgba(51, 51, 51, 0.3);
          &::before{
            content: '\f3c5';
            font-family: $icon-font-family;
            font-weight: 900;
            margin-right: 0.25em;
          }
          @include media(lg){
            display: inline-block;
            margin-top: 0;
            margin-bottom: 0;
            margin-left: 0.5em;
            width: auto;
          }
          &:hover{
            background: darken($first-color, 10%);
            box-shadow: none;
          }
        }
      }
      &.tel, &.fax {
        font-size: 0.875em;
      }
      &.mail{
        font-size: 0.875em;
        a{
          color: $body_text-color;
        }
      }

    }
  }
}

.footer_inner_upper_right {
  margin-top: 1em;
  // width: 100%;
  // @include media(lg){
  //   width: 40%;
  // }
  &_button {
    display: flex;
    align-items: center;
    justify-content: center;
    li:not(:last-child){
      margin-right: 1em;
    }
  }
  &_bnr{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex-wrap: wrap;
    @include media(md){
      flex-direction: row;
      
    }
    @include media(lg){
      justify-content: flex-end;
      padding-left: 1em;
    }
    .bnr_item{
      width: 50%;
      margin: 0.25em 0;
      @include media(md){
        width: 25%;
      }
      @include media(lg){
        width: 18rem;
      }
      a{
        display: block;
      }
      & + .bnr_item{
        @include media(md){
          padding-left: .5em;
        }
      }
    }
  }
}

.footer_inner_lower {
  padding: 1em 0;
  &_list {
    display: flex;
    align-items: center;
    justify-content: center;
    li:not(:last-child){
      margin-right: 1em;
    }
    a {
      color: $body_text-color;
      font-size: 0.875em;
      transition: 0.25s;
      &:hover{
        color: $first-color;
      }
    }
    @include media(lg){
      justify-content: start;
    }
  }
}