@charset 'UTF-8';

// レイアウト
// ========== ========== ========== ========== ========== ==========



// breakpoints
//
$breakpoints: (
  'md' : 760,
  'lg' : 980
  ) !default;



// fluid [ true or false ]
//
$fluid-sm: true;
$fluid-md: true;
$fluid-lg: true;



// container
// ---------

// .container の横幅
$container-width: (
  'sm': 100%,
  'md': 100%,
  'lg': 1110px
) !default;

// .container-wrapper の左右の padding
$container-padding: (
  'sm': 16px,
  'md': 32px,
  'lg': 44px
) !default;

// container-wrapper の background-color
$background-container-wrapper: transparent !default;

// container の background-color
$background-container: transparent !default;


// .section-inner
// --------------

// .section-inner-upper .section-inner .section-inner-lower 上下の余白（単位：em）
$section-gutter: (
  'sm': 2em,
  'md': 2em,
  'lg': 2em
) !default;


// .element
// --------

// .element 上下の余白（単位：em）
$element-gutter: (
  'sm': 1em,
  'md': 1em,
  'lg': 1em
) !default;


// header & main & footer の z-index
// --------------------------------

$z-index: (
  'header'    : 2,
  'main'      : 0,
  'footer'    : 1,
  'copyright' : 1
) !default;
