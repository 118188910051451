@charset 'UTF-8';

// 汎用 margin padding
// ------------------
$common-mp-md-scale: map-get( $base-font-size, md ) / map-get( $base-font-size, sm );
$common-mp-lg-scale: map-get( $base-font-size, lg ) / map-get( $base-font-size, sm );

//

// margin & padding
// ----------------
@for $i from 1 through 25 {
  .mt#{$i * 4} {
    margin-top: #{($i * 4) / 10}rem !important;
    @include media(md) {
      margin-top: #{($i * 4) / 10 * $common-mp-md-scale}rem !important;
    }
    @include media(lg) {
      margin-top: #{($i * 4) / 10 * $common-mp-lg-scale}rem !important;
    }
  }
  .mb#{$i * 4} {
    margin-bottom: #{($i * 4) / 10}rem !important;
    @include media(md) {
      margin-bottom: #{($i * 4) / 10 * $common-mp-md-scale}rem !important;
    }
    @include media(lg) {
      margin-bottom: #{($i * 4) / 10 * $common-mp-lg-scale}rem !important;
    }
  }
  .mr#{$i * 4} {
    margin-right: #{($i * 4) / 10}rem !important;
    @include media(md) {
      margin-right: #{($i * 4) / 10 * $common-mp-md-scale}rem !important;
    }
    @include media(lg) {
      margin-right: #{($i * 4) / 10 * $common-mp-lg-scale}rem !important;
    }
  }
  .ml#{$i * 4} {
    margin-left: #{($i * 4) / 10}rem !important;
    @include media(md) {
      margin-left: #{($i * 4) / 10 * $common-mp-md-scale}rem !important;
    }
    @include media(lg) {
      margin-left: #{($i * 4) / 10 * $common-mp-lg-scale}rem !important;
    }
  }
  .pt#{$i * 4} {
    padding-top: #{($i * 4) / 10}rem !important;
    @include media(md) {
      padding-top: #{($i * 4) / 10 * $common-mp-md-scale}rem !important;
    }
    @include media(lg) {
      padding-top: #{($i * 4) / 10 * $common-mp-lg-scale}rem !important;
    }
  }
  .pb#{$i * 4} {
    padding-bottom: #{($i * 4) / 10}rem !important;
    @include media(md) {
      padding-bottom: #{($i * 4) / 10 * $common-mp-md-scale}rem !important;
    }
    @include media(lg) {
      padding-bottom: #{($i * 4) / 10 * $common-mp-lg-scale}rem !important;
    }
  }
  .pr#{$i * 4} {
    padding-right: #{($i * 4) / 10}rem !important;
    @include media(md) {
      padding-right: #{($i * 4) / 10 * $common-mp-md-scale}rem !important;
    }
    @include media(lg) {
      padding-right: #{($i * 4) / 10 * $common-mp-lg-scale}rem !important;
    }
  }
  .pl#{$i * 4} {
    padding-left: #{($i * 4) / 10}rem !important;
    @include media(md) {
      padding-left: #{($i * 4) / 10 * $common-mp-md-scale}rem !important;
    }
    @include media(lg) {
      padding-left: #{($i * 4) / 10 * $common-mp-lg-scale}rem !important;
    }
  }
}

.m0  { margin         : 0 !important; }
.mt0 { margin-top     : 0 !important; }
.mb0 { margin-bottom  : 0 !important; }
.ml0 { margin-left    : 0 !important; }
.mr0 { margin-right   : 0 !important; }
.p0  { padding        : 0 !important; }
.pt0 { padding-top    : 0 !important; }
.pb0 { padding-bottom : 0 !important; }
.pl0 { padding-left   : 0 !important; }
.pr0 { padding-right  : 0 !important; }

// text-align
// ----------
.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-justify {
  text-align-last: justify;
  text-justify: inter-ideograph;
}

// font color
// ----------
@each $name, $color in $color-pattern {
  .fc-#{$name} {
    color: $color;
  }
}

// background color
// ----------------
@each $name, $color in $color-pattern {
  .bg-#{$name} {
    background-color: $color;
    &.light {
      background-color: lighten( $color, 30% );
    }
  }
}

// font size
// ---------
@each $name, $size in $font-size {
  .#{$name} {
    font-size: $size !important;
  }
}

// Line height
// -----------
@for $i from 10 through 20 {
  .lh-#{$i} {
    line-height: #{$i * 0.1} !important;
  }
}

// Width
// -----
@for $i from 1 through 20 {
  .w#{$i * 5} {
    width: #{$i * 5%} !important;
  }
}

// Common
// ------
.clearfix::after {
  content: '';
  display: block;
  clear: both;
}

.center-block {
  display: block;
  margin: 0 auto;
}

//太字
.fw-bold{
  font-weight: bold !important;
}

//細字
.fw-normal{
  font-weight: normal !important;
}

//インタビューとかのダッシュ
.dash{
  &:before {
    content: "\2014\2014";
    letter-spacing: -0.3em;
    margin-right: 0.5em;
  }
}

//マーカー
.marker{
  background: linear-gradient(transparent 75%, #fff799 75%);
}

//PCのみ電話を押せないように
a[href*="tel"]{
  @include media(md) {
    color: $body-text-color;
    text-decoration: none;
    pointer-events: none;
  }
}

//flex内の左右上下寄せ
.mt-auto { margin-top     : auto !important; }
.mb-auto { margin-bottom  : auto !important; }
.ml-auto { margin-left    : auto !important; }
.mr-auto { margin-right   : auto !important; }

//動画.map埋め込み
.iframe-wrapper {
  position: relative;
  width: 100%;
  &:before {
    content:"";
    display: block;
    padding-top: 56.25%; /* 高さと幅の比を16:9に固定。9/16*100=56.25 */
  }
  .iframe-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

//丸囲み数字リスト
ol.maru-num {
  li{
    padding-left: 1.25em;
    position: relative;
    &:before {
      position: absolute;
      left: 0;
    }
    @for $i from 0 through 9 {
      $result: #{\0#{2460 + $i}};
      &:nth-child(#{$i + 1})::before {
        content: #{'"' + $result + '"'};
      }
    }
  }
}

//横並び定義リスト
dl.dl-horizon {
  & dt, dd {
    line-height: 1.5;
  }
  & dt {
    float: none;
    font-weight: normal;
    &:not(:first-child) {
      margin-top: 0.5em;
      @include media(lg) {
        margin-top: 0.25em;
      }
    }
    @include media(lg) {
      float: left;
      clear: left;
      margin-top: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  & dd {
    &:not(:first-child) {
      margin-top: 0.25em;
    }
  }
  &::after {
    content: "";
    display: block;
    clear: both;
  }
  @for $i from 1 through 40 {
    &.dt#{$i}{
      & dt{
        @include media(lg) {
          width: #{$i}em;
        }
      }
      & dd {
        @include media(lg) {
          margin-left: #{$i}em;
        }
      }
    }
  }
}

//均等幅テーブル
table.fixed{
  table-layout: fixed;
}

//画像とかの枠線
.border{
  border: solid 1px #ccc;
}

//複数行ボタン
.button.multiple{
  line-height: 1.5 !important;
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  min-height: 100%;
  & > span{
    padding: 0 1.5em;
    display: block;
    position: relative;
    &::after{
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
    }
    &.left{
      &::before{
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
      }
    }
  }
}

//input[type=”number”]のスピンボタンを消す
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  margin: 0;
  -webkit-appearance: none;
  -moz-appearance:textfield;
}

.sm-order-1{
  order: 1;
  @include media(md) {
    order: 0;
  }
}
.sm-order-2{
  order: 2;
  @include media(md) {
    order: 0;
  }
}
.sm-order-3{
  order: 3;
  @include media(md) {
    order: 0;
  }
}
.sm-order-4{
  order: 4;
  @include media(md) {
    order: 0;
  }
}
.sm-order-5{
  order: 5;
  @include media(md) {
    order: 0;
  }
}

.md-order-1{
  order: 1;
  @include media(lg) {
    order: 0;
  }
}
.md-order-2{
  order: 2;
  @include media(lg) {
    order: 0;
  }
}
.md-order-3{
  order: 3;
  @include media(lg) {
    order: 0;
  }
}
.md-order-4{
  order: 4;
  @include media(lg) {
    order: 0;
  }
}
.md-order-5{
  order: 5;
  @include media(lg) {
    order: 0;
  }
}