@charset 'UTF-8';

// Breadlist Variables
// --------------------

// 項目同士の余白
$form-field-margin: (
  'sm': 1em,
  'md': 1em,
  'lg': 1em
) !default;
