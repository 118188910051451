@charset 'UTF-8';

/*
*  module.scss
*  @update 2019.10.28
*/

@import 'functions'; // 共通して使用する関数など

@import 'variables/variables'; // 共通して使用する変数

@import 'mixin'; // Mixin

@import 'initialize'; // ページの初期設定：html, body などの基本設定

@import 'animation'; // アニメーション

@import 'layout'; // レイアウト全般( .common-wrapper .common-column .container .section-inner .element )

@import 'header';

@import 'footer';

@import 'copyright';

@import 'grid'; // グリッドシステム

//@import 'grid-layout'; // CSS Grid Layout

@import 'flex'; // フレックス

@import 'typography'; // 見出しなどのテキスト処理

@import 'label'; // ラベル

@import 'button'; // ボタン

@import 'burger'; // バーガーメニューアイコン

@import 'navigation'; // ナビゲーション

@import 'pagination'; // ページネーション

@import 'list'; // リスト関連

@import 'icon'; // アイコン関連

@import 'form'; // フォーム

@import 'breadlist'; // パンくずリスト

@import 'block'; // 囲みのあるボックス

@import 'alert'; // 背景色だけの囲み

@import 'table'; // テーブル

@import 'tab'; // タブ（ javascript と関連している

@import 'pagetop'; // ページトップ（ javascript と関連している

@import 'accordion'; // アコーディオン（ javascript と関連している

@import 'adjoin'; // 隣接する要素に margin を付ける

@import 'utilities'; // 汎用で使用するユーティリティ

@import 'pages'; // トップページや下層ページで使用する

@import 'fonts/fontawesome/fontawesome'; // fontawesome v5.3.1 使用ファイル 'variables/_var_icon.scss', '_function.scss', '_icon.scss', '_button.scss', '_list.scss'

@import 'fonts/yakuhanjp/yakuhanjp_s'; // 約物半角（〈〉《》「」『』【】〔〕（）［］｛｝）yakuhanjp にすると約物全部（、。！？〈〉《》「」『』【】〔〕・（）：；［］｛｝
