@charset 'UTF-8';



// button
// ========== ========== ========== ========== ========== ==========



// $font-size 配列から key を取り出してリスト化
//
$font-size-list: map-keys( $font-size );

// リストの順番 'exlarge', 'xxlarge', 'xlarge', 'large', 'medium', 'small', 'xsmall'
//
$button-size-padding-vertical-array:   ( .5em, .5em, .6em, .6em, .7em, .7em, .7em );
$button-size-padding-horizontal-array: ( .5em, .5em, .6em, .6em, .7em, .7em, .7em );

// map-zip( $keys, $values ) キーのリスト、値のリストの 2 つから新しい map を生成して返す
//
$button-size-padding-vertical:   map-zip( $font-size-list, $button-size-padding-vertical-array );
$button-size-padding-horizontal: map-zip( $font-size-list, $button-size-padding-horizontal-array );

// ボタンの中 上下の padding
$button-padding-vertical: (
  sm: .7em,
  md: .7em,
  lg: .7em
  ) !default;

// ボタンの中 左右の padding
$button-padding-horizontal: (
  sm: 1em,
  md: 1em,
  lg: 1em
  ) !default;

$button-border-radius: 0px !default; // 角丸

$button-border: 2px !default;        // アウトライン時のボーダーの太さ

// Color
// -----
// background-color, color
$button-color: (
  'base'   : ( 'bg' : $base-color,   'color' : #fff ),
  'first'  : ( 'bg' : $first-color,  'color' : #fff ),
  'second' : ( 'bg' : $second-color, 'color' : #fff ),
  'third'  : ( 'bg' : $third-color,  'color' : #fff ),
  'fourth' : ( 'bg' : $fourth-color, 'color' : #fff ),
  'five'   : ( 'bg' : $five-color,   'color' : #fff )
) !default;

$button-hover-color: (
  'base'   : ( 'bg' : darken($base-color, 10%),   'color' : #fff ),
  'first'  : ( 'bg' : darken($first-color, 10%),  'color' : #fff ),
  'second' : ( 'bg' : darken($second-color, 10%), 'color' : #fff ),
  'third'  : ( 'bg' : darken($third-color, 10%),  'color' : #fff ),
  'fourth' : ( 'bg' : darken($fourth-color, 10%), 'color' : #fff ),
  'five'   : ( 'bg' : darken($five-color, 10%),   'color' : #fff )
) !default;

// outline
// background-color, color, border-color
$button-outline-color: (
  'base'   : ( 'bg' : #fff, 'color' : $base-color,   'border' : $base-color ),
  'first'  : ( 'bg' : #fff, 'color' : $first-color,  'border' : $first-color ),
  'second' : ( 'bg' : #fff, 'color' : $second-color, 'border' : $second-color ),
  'third'  : ( 'bg' : #fff, 'color' : $third-color,  'border' : $third-color ),
  'fourth' : ( 'bg' : #fff, 'color' : $fourth-color, 'border' : $fourth-color ),
  'five'   : ( 'bg' : #fff, 'color' : $five-color,   'border' : $five-color )
) !default;

$button-outline-hover-color: (
  'base'   : ( 'bg' : $base-color,   'color' : #fff, 'border' : $base-color ),
  'first'  : ( 'bg' : $first-color,  'color' : #fff, 'border' : $first-color ),
  'second' : ( 'bg' : $second-color, 'color' : #fff, 'border' : $second-color ),
  'third'  : ( 'bg' : $third-color,  'color' : #fff, 'border' : $third-color ),
  'fourth' : ( 'bg' : $fourth-color, 'color' : #fff, 'border' : $fourth-color ),
  'five'   : ( 'bg' : $five-color,   'color' : #fff, 'border' : $five-color )
) !default;
