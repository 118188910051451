@charset 'UTF-8';

// Pagetop
// -------
.pagetop {
  display: none;
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  z-index: 999;
  a {
    display: block;
    background: rgba( $base-color, 1);
    text-align: center;
    color: #fff;
    //line-height: 60px;
    font-size: 14px;
    text-decoration: none;
    //border-radius: 50%;
    transition: background .25s ease-out, color .25s ease-out;
    width: 44px;
    height: 44px;
    padding: .4rem;
    @include media(md) {
      width: 50px;
      height: 50px;
    }
    &:after {
      content: '';
      display: inline-block;
      width: 18px;
      height: 18px;
      border-right: 3px solid #fff;
      border-top: 3px solid #fff;
      transform: rotate(-45deg);
      margin-top: 1em;
      transition: transform .3s ease-out;
      @include media(md) {
        width: 20px;
        height: 20px;
        margin-top: 1.125em;
      }
    }
  }
}
