@charset 'UTF-8';

// Functions
// ========== ========== ========== ========== ========== ==========



// 単位を em に変換
// @used breakpoints
//
@function convertEm( $width )
{
  @return '#{ $width / 16 }em';
}




// grid
//
@function grid-margin( $key, $i: null )
{
  @if( $i != null ) {
    @return map-get( $grid-gutter, $key ) / 2 / $i / 10 + rem;
  } @else {
    @return map-get( $grid-gutter, $key ) / 2 / 10 + rem;
  }
}



// 配列に単位をつける
//
@function map-unit( $value, $key, $unit: null )
{
  @if( $unit == rem ) {
    @return map-get( $value, $key ) / 10 + $unit;
  } @else if( $unit == null ) {
    @return map-get( $value, $key );
  } @else {
    @return map-get( $value, $key ) + $unit;
  }
}

// 二次元配列
//
@function multi-array( $array, $key, $prop )
{
  $index: map-get( $array, $key );
  @return map-get( $index, $prop );
}


// map-zip( $keys, $values )
// キーのリスト、値のリストの 2 つから新しい map を生成して返す
//
@function map-zip( $keys, $values )
{
  $length: length( $keys );
  $map: ();

  @for $i from 1 through $length {
    $tmp-keys: nth( $keys, $i );
    $tmp-values: nth( $values, $i );
    $tmp-map: ( $tmp-keys : $tmp-values );
    $map: map-merge( $map, $tmp-map );
  }
  @return $map;
}
