@charset 'UTF-8';

// Alert
// -----

.alert {
  background: multi-array( $alert-color, 'base', 'bg' );
  border: $alert-border solid transparent;
  border-radius: $alert-border-radius;
  box-shadow: $alert-box-shadow;
  color: multi-array( $alert-color, 'base', 'color' );
  line-height: map-unit( $paragraph-line-height, sm );
  padding: 1.5em;
  @include media(md) {
    line-height: map-unit( $paragraph-line-height, md );
  }
  @include media(lg) {
    line-height: map-unit( $paragraph-line-height, lg );
  }

  // Color Pattern theme color
  @each $name, $value in $alert-color {
    &.#{$name} {
      background: map-get( $value, 'bg' );
      box-shadow: $alert-box-shadow;
      color: map-get( $value, 'color' );
    }
  }
  // Color Pattern gray & common color
  @each $name, $value in $basic-colors {
    &.#{$name} {
      background: $value;
      box-shadow: $alert-box-shadow;
      color: inherit;
    }
  }
}

// Outline
// -------

.alert.outline {
  background: multi-array( $alert-outline-color, 'base', 'bg' );
  border: $alert-border solid multi-array( $alert-outline-color, 'base', 'border' );
  color: multi-array( $alert-outline-color, 'base', 'color' );
  text-shadow: none;

  // Color Pattern theme color
  @each $name, $value in $alert-outline-color {
    &.#{$name} {
      background: map-get( $value, 'bg' );
      border: $alert-border solid map-get( $value, 'border' );
      color: map-get( $value, 'color' );
    }
  }
  // Color Pattern gray & common color
  @each $name, $value in $basic-colors {
    &.#{$name} {
      background: $white-color;
      border: $alert-border solid $value;
      color: inherit;
    }
  }
}
