@charset 'UTF-8';

// Common Font size
// ----------------
$font-size: (
  'exlarge' : 200%,
  'xxlarge' : 150%,
  'xlarge'  : 125%,
  'large'   : 112.5%,
  'medium'  : 87.5%,
  'small'   : 75%,
  'xsmall'  : 62.5%
  ) !default;


// Heading
// -------

$h1-font-size:   2em;
$h2-font-size:   1.75em;
$h3-font-size:   1.5em;
$h4-font-size:   1.375em;
$h5-font-size:   1.25em;
$h6-font-size:   1.125em;


// Paragraph
// ---------

// p タグ内の letter-spacing（単位は自由）
$paragraph-letter-spacing: (
  sm: 0,
  md: 0,
  lg: 0
) !default;

// p タグ内の line-height
$paragraph-line-height: (
  sm: 1.8,
  md: 1.8,
  lg: 2
) !default;
