@charset 'UTF-8';

/**
*  tab
*  @since 2019.6.10
*/

.tab_wrapper {
  width: 100%;
}

ul.tab_label {
  border-bottom: 1px solid $gray-300;
  display: flex;
  flex-flow: row wrap;

  li {
    border-top: 1px solid $gray-300;
    border-left: 1px solid $gray-300;
    flex: 1 0 auto;
    position: relative;

    &:last-child {
      border-right: 1px solid $gray-300;
    }

    .tab_label_item {
      background: $gray-200;
      cursor: pointer;
      color: $gray-400;
      display: block;
      padding: 1em 0;
      text-align: center;
      &:hover {
        color: $gray-600;
      }
      &.tab_current {
        background: $white-color;
        color: $body-text-color;
      }
    }
  }
}

.tab_content {
  border-left: 1px solid $gray-300;
  border-right: 1px solid $gray-300;
  border-bottom: 1px solid $gray-300;
  padding: 1rem;
}
