@charset 'UTF-8';

// header
// ------

header {
  background: transparent;
  padding-bottom: map-unit( $header-padding-bottom, sm );
  width: 100%;
  z-index: map-unit($z-index, header);
  @include media(md) {
    padding-bottom: map-unit( $header-padding-bottom, md );
  }
  @include media(lg) {
    padding-bottom: map-unit( $header-padding-bottom, lg );
  }
}

.header-wrap {
  background: $background-header;
  border-top: 4px solid $base-color;
  border-bottom: 1px solid $gray-200;
  position: relative;
  padding: 0 map-unit( $header-container-padding, sm );
  @include media(md) {
    padding: 0 map-unit( $header-container-padding, md );
  }
  @include media(lg) {
    border-bottom: none;
    padding: 0 map-unit( $header-container-padding, lg );
  }
  @include widthWrapper( $header-container-width, $header-container-padding );
}

.header-container {
  margin: 0 auto;
  @include widthContainer( $header-container-width );
}

.header-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: map-unit( $height-header, sm, rem );
  @include media(md) {
    height: map-unit( $height-header, md, rem );
  }
  @include media(lg) {
    height: map-unit( $height-header, lg, rem );
  }

  .header-inner-left {

    .header_title {
      display: flex;
      align-items: center;
      img {
        display: block;
        margin-right: .5em;
        width: 32px;
        @include media(md){
          width: 42px;
        }
        @include media(lg){
          width: 48px;
        }
      }
    }

    .header_logo_text {
      font-size: 1.6rem;
      font-weight: 700;
      line-height: 1.4;
      &_sub {
        color: $gray-600;
        font-size: 64%;
      }
      &_name {
        color: $base-color;
      }
      @include media(md){
        font-size: 1.8rem;
      }
      @include media(lg){
        font-size: 2.2rem;
      }
    }
  } //.header-inner-left

  .header-inner-right {
    .header_address_wrapper {
      display: none;
      @include media(lg){
        display: block;
        white-space: nowrap;
        p {
          font-size: 1.2rem;
          letter-spacing: .35em;
          margin: 0 0 .2em;
          text-align: center;
        }
        .header_address_inner {
          display: flex;
          align-items: center;
          line-height: 1.2;
          figure {
            margin-right: 2rem;
            img {
              width: 2.4rem;
              height: 4.4rem;
            }
          }
          address, em {
            font-style: normal;
          }
          address {
            font-size: 2.4rem;
          }
          .address{
            font-size: 1.4rem;
            margin-top: 0.5rem;
          }
          .phone{
            font-size: 2.4rem;
            font-weight: 500;
          }
          em {
            display: block;
            font-size: 1.3rem;
            font-weight: normal;
            letter-spacing: .07em;
          }
        }
      }
    }
  }
}
