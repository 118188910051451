@charset 'UTF-8';

// copyright
// ---------

// .copyright-container の横幅 .container と合わせたい場合は $container-width を使用する
$copyright-container-width: (
  'sm': map-get( $container-width, sm ),
  'md': map-get( $container-width, md ),
  'lg': map-get( $container-width, lg )
) !default;

// .copyright-container の左右の padding .container と合わせたい場合は $container-padding を使用する
$copyright-container-padding: (
  'sm': map-get( $container-padding, sm ),
  'md': map-get( $container-padding, md ),
  'lg': map-get( $container-padding, lg )
) !default;

$background-copyright: transparent !default; // copyright の background-color
